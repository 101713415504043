import { useDesign } from 'contexts/design';
import { useProgram } from 'contexts/program';
import { RenderingVariables } from 'models/publisher/block';

type UnimplementedKeys =
  | 'content_permalink'
  | 'permalink_text'
  | 'published_at_iso'
  | 'read_time_in_seconds';

type DesignFieldVariables = Required<
  Omit<RenderingVariables, UnimplementedKeys>
> &
  Pick<RenderingVariables, UnimplementedKeys>;
export const useFieldVariables = (): DesignFieldVariables => {
  const program = useProgram();
  const { design } = useDesign();

  return {
    program_theme_color: program.themeColor || '',
    program_accent_color: program.secondaryColor || '',
    program_header_image: program.headerImageUrl || '',
    program_icon_image: program.iconImageUrl || '',
    program_logo_image: program.logoImageUrl || '',
    author_name:
      design.meta.author_name || program?.programAuthor?.displayName || '',
    author_avatar:
      design.meta.author_avatar === undefined
        ? program?.programAuthor?.avatarUrl || ''
        : design.meta.author_avatar,
    // TODO: implement read time in seconds
    // read_time_in_seconds: postData?.content?.readTimeInSeconds,
  };
};
