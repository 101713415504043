import React from 'react';
import type { Step } from 'models/journeys/journey';
import { useJourneyState } from 'contexts/journeys/journey';
import { useJourneyInsightsMetricsQuery } from '../../../../../../../hooks/journeyInsightsMetrics';
import { useJourneyMetricsQuery } from '../../../../../../../hooks/journeys/journey-metrics';
import { useProgram } from '../../../../../../../contexts/program';
import { Loading } from '../../../../../../../components/publisher/blocks/instances/Loading';
import { NoDataChart } from '../../../../../../../shared/Charts/NoDataChart';
import { ReportingSection } from './ReportingSection';
import { CompactStepCard } from './Cards/CompactStepCard';
import { ChannelDetailCard } from './Cards/ChannelDetailCard';

import styles from '../step-reporting.module.css';

export const StepReportingV2: React.FC<{
  step: Step;
}> = ({ step }) => {
  const { id } = useProgram();
  const journeyState = useJourneyState();
  const { journey, currentGraph } = journeyState;

  if (
    id === undefined ||
    journey?.id === undefined ||
    currentGraph?.id === undefined
  ) {
    return <Loading />;
  }
  return (
    <StepReportingV2Inner
      programId={id}
      step={step}
      journeyId={journey.id}
      graphId={currentGraph.id}
    />
  );
};

const StepReportingV2Inner: React.FC<{
  programId: number;
  journeyId: number;
  graphId: number;
  step: Step;
}> = ({ programId, journeyId, graphId, step }) => {
  const { data: journeyData } = useJourneyMetricsQuery({
    programId,
    journeyId,
    graphId,
  });
  const { isLoading, data } = useJourneyInsightsMetricsQuery({
    journeyId,
    graphId,
    executionId: journeyData?.executionId || -1,
    stepId: step.id || 0,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <div className={styles.noDataContainer}>
        <NoDataChart />
      </div>
    );
  }

  /**
   * We decided to hardcode these values. I'm sorry.
   */
  const {
    communicationsSent,
    communicationsDelivered,
    pushNotificationSent,
    pushNotificationDelivered,
    pushNotificationOpened,
    notificationSent,
    notificationDelivered,
    notificationOpened,
    emailSent,
    emailOpened,
    emailDelivered,
    emailClicked,
  } = data;

  return (
    <>
      <ReportingSection title="Overview">
        <div className={styles.reportingSectionBody}>
          <CompactStepCard
            numerator={communicationsDelivered}
            denominator={communicationsSent}
            units="Communications Sent"
            label="Communications Delivered"
            tooltip="Nothing to see here"
          />
        </div>
      </ReportingSection>
      <ReportingSection title="Delivery Channels">
        <div className={styles.reportingSectionBody}>
          <ChannelDetailCard
            label="Email"
            content={{
              children: [
                { label: 'Sent', value: emailSent, children: [] },
                { label: 'Delivered', value: emailDelivered, children: [] },
                { label: 'Opened', value: emailOpened, children: [] },
                { label: 'Clicked', value: emailClicked, children: [] },
              ],
            }}
          />
          <ChannelDetailCard
            label="Notification Center"
            content={{
              children: [
                { label: 'Sent', value: notificationSent, children: [] },
                {
                  label: 'Delivered',
                  value: notificationDelivered,
                  children: [],
                },
                { label: 'Opened', value: notificationOpened, children: [] },
              ],
            }}
          />
          <ChannelDetailCard
            label="Push Notification"
            content={{
              children: [
                { label: 'Sent', value: pushNotificationSent, children: [] },
                {
                  label: 'Delivered',
                  value: pushNotificationDelivered,
                  children: [],
                },
                {
                  label: 'Opened',
                  value: pushNotificationOpened,
                  children: [],
                },
              ],
            }}
          />
        </div>
      </ReportingSection>
    </>
  );
};
