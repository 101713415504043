import { createContext, useContext } from 'react';
import { Design } from 'models/design';
import { Template } from 'models/library';
import { DesignAuthor } from 'components/channel-selection/inputs';

export type DesignError = { detail: string };
type DesignData = {
  attributes: Design;
};

export const defaultDesign: Design = {
  id: 'new',
  name: '',
  blocks: [],
  meta: {},
};

export type DesignStatus = {
  isSaving: boolean;
  isLoading: boolean;
  hasLoaded: boolean;
  isModified: boolean;
  error?: 'unauthorized' | 'not-found';
};

export type usePersistDesign = {
  save: (
    options?: Partial<{
      design: Design;
      onSuccess: (design?: DesignData) => void;
      onError: (errors: Array<DesignError>) => void;
      callback: (design?: Design) => void;
    }>
  ) => void;
  status: DesignStatus;
};

export type UseDesign = {
  id: number | 'new';
  design: Design;
  update: (changes: Design) => void;
  updateAuthor: (author: DesignAuthor) => void;
  save: usePersistDesign['save'];
  error?: string;
  isProcessing: boolean;
  status: DesignStatus;
  active: boolean;
  parentType?: string;
  createFromTemplate: (template: Template) => void;
  createFromLibraryTemplate: (t?: string) => void;
  refetch: () => void;
};

export const defaultDesignStatus: DesignStatus = {
  isSaving: false,
  isLoading: false,
  hasLoaded: false,
  isModified: false,
};

const contextPrototype: UseDesign = {
  id: 'new',
  design: { ...defaultDesign },
  update: () => {},
  updateAuthor: () => {},
  save: () => {},
  status: defaultDesignStatus,
  isProcessing: false,
  active: false,
  createFromLibraryTemplate: () => {},
  createFromTemplate: () => {},
  refetch: () => {},
};

export const DesignContext = createContext(contextPrototype);

export const useDesign = (): UseDesign => {
  const context = useContext(DesignContext);

  if (context === undefined) {
    throw new Error(
      'Design context hooks require a containing Design Provider'
    );
  }

  return context;
};
