import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Logo } from 'shared/icons';
import { PageTemplate } from 'DesignSystem/Layout/Pages';
import styles from 'App/PermalinkPreview/permalink-preview.module.css';

export const PermalinkPreview: React.FC<RouteComponentProps> = () => {
  return (
    <PageTemplate noSiteHeader noNavigation title="Preview">
      <div className={styles.main}>
        <div className={styles.info_box}>
          <Logo height="80" width="80" className={styles.firstup_logo} />
          <p className={styles.message}>
            You clicked a link in a preview email for a campaign that is not yet
            published. <br />
            Once published, this link will open the campaign in the Employee
            Experience.
            <br />
            <br />
            <a href="compose/design">Click here</a> to visit the content editor,
            or close this tab if you already have the editor open.
          </p>
        </div>
      </div>
    </PageTemplate>
  );
};

export const JourneysPermalinkPreview: React.FC<RouteComponentProps<{
  journeyId: string;
}>> = ({ journeyId }) => {
  return (
    <PageTemplate noSiteHeader noNavigation title="Preview">
      <div className={styles.main}>
        <div className={styles.info_box}>
          <Logo height="80" width="80" className={styles.firstup_logo} />
          <p className={styles.message}>
            You clicked a link in a preview email for a Journey communication
            that is not yet published. <br />
            Once published, this link will open the communication in the
            Employee Experience.
            <br />
            <br />
            <a href={journeyId !== 'new' ? 'edit' : '../current'}>
              Click here
            </a>{' '}
            to visit the journey editor, or close this tab if you already have
            the editor open.
          </p>
        </div>
      </div>
    </PageTemplate>
  );
};
