import React, { useCallback, useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Box } from 'DesignSystem/Components/Box';
import {
  Body,
  Span,
  styles,
  FieldLabel,
  Subheading,
} from 'DesignSystem/Typography';
import { Pills } from 'DesignSystem/Components/Pills';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Checkbox } from 'shared/Checkbox';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import {
  CUSTOM_TOPIC_LABEL_MAX_LENGTH,
  FEATURED_LABELS,
  Settings,
} from 'models/publisher/settings';
import { Topic } from 'models/topic';
import { WhitePillClass } from 'DesignSystem/Components/Pill';
import { Label } from 'models/label';
import { usePrevious } from 'hooks/use-previous';
import { SelectTopic } from './SelectTopic';
import { SuggestTopics } from './SuggestTopics';
import { FeaturedLabelsDropdown } from '../FeaturedLabel';
import { SaveModalButtons } from '../Shared/SaveModalButtons';
import { archivedTopics } from '../Shared/common-utils';

interface AddTopicsProps {
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  setDismissable?: (dismissable: boolean) => void;
}

const Bold = Span(styles.Bold);

export const AddTopics: React.FC<AddTopicsProps> = ({
  disabled,
  onCancel,
  onSave,
  setDismissable,
}) => {
  const {
    settings,
    fields: { contentTopics: setContentTopics },
  } = useSettings();

  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

  const [selectedTopics, setSelectedTopics] = useState<Array<Topic>>(
    settings.contentTopics
  );

  const [selectedFeaturedLabel, setSelectedFeaturedLabel] = useState<
    Label | undefined
  >(settings.contentLabel);

  const [includeInResources, setIncludeInResources] = useState(
    settings.isResource
  );
  const [includeInFeatured, setIncludeInFeatured] = useState(
    settings.isFeatured
  );
  const previousIncludeInFeatured = usePrevious(includeInFeatured);

  const toggledInFeaturedOn =
    previousIncludeInFeatured === false && includeInFeatured === true;
  const toggledInFeaturedOff =
    previousIncludeInFeatured === true && includeInFeatured === false;

  if (toggledInFeaturedOn && selectedFeaturedLabel === undefined) {
    setSelectedFeaturedLabel(FEATURED_LABELS[0]);
  } else if (toggledInFeaturedOff && selectedFeaturedLabel !== undefined) {
    setSelectedFeaturedLabel(undefined);
  }

  const {
    permissions: { setFeatured },
  } = usePermissions();

  const { id: programId } = useProgram();

  const { resourcesEnabled } = useFeatureFlags();

  const handleFeaturedPermission =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Featured').data
      ?.value as boolean) || false;

  const isSuggestedTopicsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Publish.SuggestedTopics').data
      ?.value as boolean) || false;

  const updateTopics = useCallback(
    (
      contentTopics: Settings['contentTopics'],
      isFeatured: Settings['isFeatured'],
      isResource: Settings['isResource'],
      contentLabel: Settings['contentLabel']
    ) => {
      setContentTopics.set(contentTopics, {
        isFeatured,
        isResource,
        contentLabel,
      });
    },
    [setContentTopics]
  );

  const renderPill = (value: Topic) => (
    <>
      {value.name}&nbsp;<Bold>·</Bold>&nbsp;{value.followerCount}
    </>
  );

  const isFormValid =
    !(includeInFeatured && !selectedFeaturedLabel) &&
    !(
      includeInFeatured &&
      selectedFeaturedLabel &&
      selectedFeaturedLabel.text.trim().length === 0
    );

  const hasSelectedTopics = selectedTopics.length > 0;

  useEffect(() => {
    if (!hasSelectedTopics) {
      setIncludeInResources(false);
      setIncludeInFeatured(false);
      setSelectedFeaturedLabel(undefined);
    }
  }, [hasSelectedTopics]);

  // disable dismiss if the save button has been enabled
  useEffect(() => {
    if (setDismissable) setDismissable(!saveEnabled);
  }, [setDismissable, saveEnabled]);

  const onSelectSuggestedTopic = (selectedSuggestedTopic: Topic) => {
    datadogRum.addAction('select_suggested_topic', selectedSuggestedTopic);
    if (
      !selectedTopics.some(
        (topic: Topic) => topic.id === selectedSuggestedTopic.id
      )
    ) {
      setSelectedTopics([...selectedTopics, selectedSuggestedTopic]);
      setSaveEnabled(true);
    }
  };

  const cancel = () => {
    datadogRum.addAction('cancel_topics');
    onCancel?.();
  };

  return (
    <>
      <Box>
        <Box margin={[0, 0, 16, 0]}>
          <Subheading bold>Topics</Subheading>
        </Box>
        <Box margin={[0, 0, 33, 0]}>
          <SelectTopic
            disabled={disabled}
            selectedTopics={selectedTopics}
            onTopicsChange={(topics: Topic[]) => {
              setSelectedTopics(topics);
              setSaveEnabled(true);
            }}
          />
        </Box>

        {selectedTopics.length > 0 && (
          <>
            <Box margin={[-24, 0, 0, 0]}>
              <Pills
                values={selectedTopics}
                render={renderPill}
                pillClassName={WhitePillClass}
                onClose={(topic: Topic) => {
                  setSelectedTopics(
                    selectedTopics.filter(
                      (selectedTopic: Topic) => selectedTopic.id !== topic.id
                    )
                  );
                  setSaveEnabled(true);
                }}
                valueTransformer={archivedTopics}
              />
            </Box>
            {resourcesEnabled && (
              <Box
                dataTest="resources-checkbox"
                margin={[26, 33, 0, 16]}
                style={{ display: 'flex' }}
              >
                <Checkbox
                  disabled={disabled || !hasSelectedTopics}
                  checked={includeInResources}
                  onChange={(checked: boolean) => {
                    setIncludeInResources(checked);
                    setSaveEnabled(true);
                  }}
                  label={<Body>Include in Resources</Body>}
                />
              </Box>
            )}
            {(!handleFeaturedPermission || setFeatured) && (
              <Box
                dataTest="featured-checkbox"
                margin={[16, 16]}
                style={{ display: 'flex' }}
              >
                <Checkbox
                  disabled={disabled || !hasSelectedTopics}
                  checked={includeInFeatured}
                  onChange={(checked: boolean) => {
                    setIncludeInFeatured(checked);
                    setSaveEnabled(true);
                  }}
                  label={<Body>Include in Featured</Body>}
                />
              </Box>
            )}
            {(!handleFeaturedPermission || setFeatured) && includeInFeatured && (
              <Box margin={[12, 6, 12, 57]}>
                <FieldLabel>Featured Label</FieldLabel>
                <Box margin={[15, 0, 15, 0]}>
                  <FeaturedLabelsDropdown
                    defaultSelectedLabel={selectedFeaturedLabel}
                    onChange={(label: Label | undefined) => {
                      if (
                        label &&
                        label.text.length > CUSTOM_TOPIC_LABEL_MAX_LENGTH
                      ) {
                        setSaveEnabled(false);
                      } else {
                        setSelectedFeaturedLabel(label);
                        setSaveEnabled(true);
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>

      {isSuggestedTopicsEnabled && (
        <SuggestTopics
          selectedTopics={selectedTopics}
          programId={programId}
          onSelect={onSelectSuggestedTopic}
        />
      )}

      <Box margin={[-10, 0, 0, 0]}>
        <SaveModalButtons
          onCancel={cancel}
          onSave={() => {
            datadogRum.addAction('save_topics', {
              selectedTopics,
              includeInFeatured,
              includeInResources,
              selectedFeaturedLabel,
            });
            if (saveEnabled && isFormValid) {
              updateTopics(
                selectedTopics,
                includeInFeatured,
                includeInResources,
                selectedFeaturedLabel
              );

              if (onSave) {
                onSave();
              }
            }
          }}
          disabled={!saveEnabled || !isFormValid}
        />
      </Box>
    </>
  );
};
