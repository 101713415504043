import React from 'react';
import { NodeProps } from 'reactflow';
import {
  DelayStep,
  DelayStepOptions,
  MetricsMode,
} from 'models/journeys/journey';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import cx from 'classnames';
import { useJourneyStepMetrics } from 'hooks/journeys/journey-metrics';
import { useJourneyState } from 'contexts/journeys/journey';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { BaseNode, NodeContent } from './BaseNode';
import styles from './node.module.css';
import { DelayIcon } from '../icons';
import { Metrics, Metric } from './Metrics';

export const DelayNode = React.memo((props: NodeProps) => {
  const { id } = props;
  const { journey, currentGraph, metricsMode, getStep } = useJourneyState();
  const currentStep = getStep('delay', id);

  const unit = React.useCallback((step) => {
    let label = DelayStepOptions.find(
      (f) => f.value === step.unit
    )?.label.toLowerCase();
    if (label && step.quantity === 1) {
      label = label.slice(0, -1);
    }
    return label;
  }, []);

  if (!currentStep) {
    return <div />;
  }

  return (
    <BaseNode
      key={id}
      activateOnClick
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <NodeContent>
        <Flex column start className={styles.nodeContainer}>
          <Flex
            start
            className={cx(styles.bodyContainer, styles.bodyContainerCompact)}
          >
            <Box className={styles.icon}>
              <DelayIcon />
            </Box>
            <Flex
              className={cx(styles.messageBody, styles.messageBodyCompact)}
              column
              start
            >
              <div>Delay</div>
              <div>
                Wait {currentStep.quantity} {unit(currentStep)}
              </div>
            </Flex>
          </Flex>
          {journey?.id && currentGraph?.id && currentGraph?.isLive && (
            <DelayMetrics
              journeyId={journey.id}
              graphId={currentGraph.id}
              step={currentStep}
              mode={metricsMode}
            />
          )}
        </Flex>
      </NodeContent>
    </BaseNode>
  );
});

const DelayMetrics: React.FC<{
  journeyId: number;
  graphId: number;
  step: DelayStep;
  mode: MetricsMode;
}> = ({ journeyId, graphId, step, mode }) => {
  const { data, isLoading } = useJourneyStepMetrics(
    journeyId,
    graphId,
    'delay',
    step.id
  );
  const notificationCenterEnabled = useNotificationCenterEnabled();

  if (!data) {
    return <Metrics isLoading={isLoading} />;
  }

  if (notificationCenterEnabled) {
    return (
      <Metrics isLoading={isLoading}>
        <Metric label="Entered" value={data.entered} />
        <Metric label="Current" value={data.current} />
        <Metric label="Exited" value={data.completed} />
      </Metrics>
    );
  }

  return (
    <>
      {mode === MetricsMode.members && (
        <Metrics isLoading={isLoading}>
          {data && (
            <>
              <Metric label="Entered" value={data.entered} />
              <Metric label="Current" value={data.current} />
              <Metric label="Exited" value={data.completed} />
            </>
          )}
        </Metrics>
      )}
    </>
  );
};
