import { useQuery } from 'react-query';
import {
  fetchJourneyInsightsMetrics,
  JourneyInsightsMetricsData,
} from '../services/api-journey-metrics';

import { QueryResponse } from './common';
import { useProgram } from '../contexts/program';

export const useJourneyInsightsMetricsQuery = (props: {
  journeyId: string | number;
  graphId: string | number;
  executionId: string | number;
  stepId: string | number;
}): QueryResponse<JourneyInsightsMetricsData> => {
  const { id: programId } = useProgram();
  const { journeyId, graphId, executionId, stepId } = props;
  const { isLoading, error, data } = useQuery<
    { data: JourneyInsightsMetricsData },
    Error
  >(
    [
      'journey-insights-metrics',
      { programId, journeyId, graphId, executionId, stepId },
    ],
    () =>
      fetchJourneyInsightsMetrics({
        ...props,
        programId,
        journeyId,
        graphId,
        executionId,
        stepId,
      }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};
