import React from 'react';
import { Tag } from '@socialchorus/shared-ui-components';
import { Box, Tooltip } from '../../../../../../../../DesignSystem/Components';
import { MAIcon } from '../../../../../../../../shared/MAIcon';

import styles from './Cards.module.css';

export const CompactStepCard: React.FC<{
  numerator: number;
  denominator: number;
  units: string;
  label: string;
  tooltip: string;
}> = ({ numerator, denominator, units, label, tooltip }) => {
  return (
    <Box className={styles.compactStepCard}>
      <Box className={styles.compactStepCard__header}>
        <span className="text-tertiary-heading">{label}</span>
        {tooltip && (
          <Tooltip content={tooltip}>
            <MAIcon name="info" />
          </Tooltip>
        )}
      </Box>
      <Box className={styles.compactStepCard__body}>
        <span className="text-main-heading font-normal">
          <span className="font-bold">{numerator}</span>/
          <span className="font-bold black-50">{denominator}</span>
        </span>
        <Tag compact label={`${(numerator / denominator).toFixed(0)}%`} />
        <span>{units}</span>
      </Box>
    </Box>
  );
};
