import React from 'react';
import cx from 'classnames';
import { Box } from '../../../../../../../DesignSystem/Components';

export const ReportingSection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <Box margin={[32, 0, 0, 0]}>
      <h2 className={cx('text-tertiary-heading font-bold')}>{title}</h2>
      <hr style={{ margin: '8px 0px 16px' }} />
      <Box>{children}</Box>
    </Box>
  );
};
