import { DateTime } from 'luxon';
import { logError } from 'hooks/datadog';
import {
  ComplexExpression,
  defaultComplexExpression,
  emptyExpression,
  expressionToText,
  isComplexExpression,
  isSimpleExpression,
  normalizeExpression,
  SimpleExpression,
  textToExpression,
} from 'models/expression';
import {
  CommunicationStep,
  DecisionEdge,
  DecisionStep,
  Journey,
  JourneyGraph,
  JourneyListItem,
  localeTimezone,
  RecurringTrigger,
  recurringTriggerTime,
  ScheduledTrigger,
  StartStep,
  Step,
  timeOfDay,
  TimeOfDay,
  Trigger,
  Triggers,
} from 'models/journeys/journey';
import { maybe } from 'utility/objectUtils';
import { v4 as uuidv4 } from 'uuid';

export type JourneyPaginationData = {
  total: number;
  page: {
    size: number;
    number: number;
  };
};

type ServerJourneyListData = Omit<JourneyCollectionData, 'data'> & {
  data: (Omit<JourneyListItem, 'createdAt' | 'updatedAt' | 'metrics'> & {
    createdAt?: string;
    updatedAt?: string;
    metrics?: { currentMembers: number; requestedAt: string };
  })[];
};

type ServerDecisionEdge = Omit<DecisionEdge, 'criterion'> & {
  criterion?: string;
};

type ServerDecisionStep = Omit<DecisionStep, 'next'> & {
  next: ServerDecisionEdge[];
};

type ServerCommunicationStep = Omit<CommunicationStep, 'selectedChannels'>;

export type ServerStep =
  | Exclude<Step, DecisionStep | StartStep | CommunicationStep>
  | ServerDecisionStep
  | ServerCommunicationStep;

type ServerJourneyGraph = Omit<
  JourneyGraph,
  'createdAt' | 'updatedAt' | 'rootStepId' | 'steps' | 'isLive'
> & {
  createdAt?: string;
  updatedAt?: string;
  steps: ServerStep[];
  initiation: ServerInitiation;
};

export type ServerJourney = Omit<
  Journey,
  'createdAt' | 'updatedAt' | 'liveGraph' | 'draftGraph' | 'currentGraph'
> & {
  createdAt?: string;
  updatedAt?: string;
  liveGraph?: ServerJourneyGraph;
  draftGraph?: ServerJourneyGraph;
};

type ServerTriggers = Omit<Triggers, 'scheduled' | 'recurring'> & {
  scheduled: Omit<ScheduledTrigger, 'date'> & {
    date?: string;
  };
} & {
  recurring: Omit<RecurringTrigger, 'triggerCriterion' | 'time'> & {
    triggerCriterion?: string;
    timeOfDay?: TimeOfDay;
    timeZone?: string;
  };
};

type ServerTrigger = ServerTriggers[keyof ServerTriggers];

type ServerInitiation = {
  triggers: ServerTrigger[];
  criterion?: string;
  rootStepId: string;
};

export type JourneyCollectionData = {
  data: JourneyListItem[];
  meta?: JourneyPaginationData;
};

export const deserializeJourneyListItems = (
  serverJourney: ServerJourneyListData
): JourneyCollectionData => {
  const newData = serverJourney.data.map((j) => {
    return {
      ...j,
      createdAt: maybe(j?.createdAt, DateTime.fromISO),
      updatedAt: maybe(j?.updatedAt, DateTime.fromISO),
      metrics: maybe(j?.metrics, (m) => ({
        ...m,
        requestedAt: DateTime.fromISO(m.requestedAt),
      })),
    };
  });

  return {
    ...serverJourney,
    data: newData,
  };
};

const deserializeGraph = (
  serverGraph: ServerJourneyGraph,
  isLive: boolean,
  previousStartStepId?: StartStep['id']
): JourneyGraph => {
  const startStepId = previousStartStepId ?? uuidv4();

  // Removing triggers from initiation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rootStepId = findRootStepId(serverGraph);
  const { triggers, criterion, ...initiationRest } = serverGraph.initiation;

  const startStep: StartStep = {
    ...initiationRest,
    criterion: maybe(criterion, buildComplexExpression),
    id: startStepId,
    type: 'start',
    trigger: deserializeTrigger(triggers || []),
    next: [{ targetId: rootStepId }],
  };

  const newSteps = serverGraph.steps.map((s) => {
    switch (s.type) {
      case 'decision':
        return deserializeDecisionStep(s);
      case 'communication':
        return deserializeCommunicationStep(s);
      default:
        return s;
    }
  });

  // Removing initiation from graph
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { initiation, ...graphRest } = serverGraph;

  return {
    ...graphRest,
    steps: [startStep, ...newSteps],
    createdAt: maybe(serverGraph?.createdAt, DateTime.fromISO),
    updatedAt: maybe(serverGraph?.updatedAt, DateTime.fromISO),
    isLive,
    rootStepId,
  };
};

/**
 * @param serverJourney
 * @param previousStartStepId Used when deserializing the journey graph from the server, instead of generating a new one.
 */
export const deserializeJourney = (
  serverJourney: ServerJourney,
  previousStartStepId?: StartStep['id']
): Journey => {
  const name =
    serverJourney.name && serverJourney.name !== 'Default'
      ? serverJourney.name
      : 'Unnamed Journey';

  return {
    ...serverJourney,
    name,
    draftGraph: maybe(serverJourney.draftGraph, (graph) =>
      deserializeGraph(graph, false, previousStartStepId)
    ),
    liveGraph: maybe(serverJourney.liveGraph, (graph) =>
      deserializeGraph(graph, true, previousStartStepId)
    ),
    createdAt: maybe(serverJourney?.createdAt, DateTime.fromISO),
    updatedAt: maybe(serverJourney?.updatedAt, DateTime.fromISO),
  };
};

// A journey may have an empty initiation if it was saved with
// an empty start step. The root step will not have another step
// targeting it.
const findRootStepId = (graph: ServerJourneyGraph): Step['id'] => {
  if (graph.initiation.rootStepId) return graph.initiation.rootStepId;

  const targetIds = graph.steps
    .map((step) => step.next.map(({ targetId }) => targetId))
    .flat();

  const rootStep = graph.steps.find((step) => !targetIds.includes(step.id));

  // This shouldn't happen, but if it does, we need to know about it.
  if (!rootStep)
    logError(new Error('Journeys: root step was not found.'), {
      id: graph.id,
    });

  return rootStep?.id || graph.steps[0].id;
};

const deserializeTrigger = ([trigger]: ServerTrigger[]): Trigger => {
  if (trigger?.type === 'scheduled')
    return {
      ...trigger,
      date: trigger.date ? DateTime.fromISO(trigger.date) : undefined,
    };

  if (trigger?.type === 'recurring') {
    const {
      timeOfDay: tod = recurringTriggerTime(),
      timeZone: tz = localeTimezone,
    } = trigger;
    return {
      ...trigger,
      triggerCriterion: maybe(trigger.triggerCriterion, buildSimpleExpression),
      timeOfDay: timeOfDay(tod.hour, tod.minute),
      timeZone: tz,
    };
  }
  return trigger;
};

const deserializeDecisionStep = (
  serverDecisionStep: ServerDecisionStep
): DecisionStep => {
  const newNext = serverDecisionStep.next.map((n) => {
    const expression = maybe(n?.criterion, buildComplexExpression);
    return {
      ...n,
      criterion: expression,
    };
  });

  return {
    ...serverDecisionStep,
    next: newNext,
  };
};

const deserializeCommunicationStep = (
  serverStep: ServerCommunicationStep
): CommunicationStep => {
  return {
    ...serverStep,
    selectedChannels: serverStep.channels.map((channel) => channel.name),
  };
};

const serializeGraph = (graph: JourneyGraph): ServerJourneyGraph => {
  const startStep: StartStep | undefined = graph.steps.find(
    (step): step is StartStep => step.type === 'start'
  );

  if (!startStep) throw new Error('Journey graph must have a start step');

  const { trigger, criterion } = startStep;

  const serverInit: ServerInitiation = {
    criterion: maybe(criterion, expressionToText) ?? '',
    triggers: trigger ? [serializeTrigger(trigger)] : [],
    rootStepId: graph.rootStepId,
  };

  const newSteps: ServerStep[] = graph.steps
    .map((s) => {
      switch (s.type) {
        case 'decision':
          return serializeDecisionStep(s);
        case 'communication':
          return serializeCommunicationStep(s);
        default:
          return s;
      }
    })
    .filter((s): s is ServerStep => s.type !== 'start');

  // Removing createdAt and updatedAt from journey graph
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { createdAt, updatedAt, ...serverGraph } = graph;

  return {
    ...serverGraph,
    initiation: serverInit,
    steps: newSteps,
  };
};

export const serializeJourney = (journey: Journey): ServerJourney => {
  const draftGraph = maybe(journey.draftGraph, serializeGraph);
  const liveGraph = maybe(journey.liveGraph, serializeGraph);

  // Removing createdAt and updatedAt from journey
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { createdAt, updatedAt, ...serverJourney } = journey;

  return {
    ...serverJourney,
    draftGraph,
    liveGraph,
  };
};

const serializeTrigger = (trigger: Trigger): ServerTrigger => {
  if (trigger.type === 'scheduled')
    return { ...trigger, date: trigger.date?.toISO() || undefined };

  if (trigger.type === 'recurring') {
    return {
      ...trigger,
      triggerCriterion: maybe(trigger.triggerCriterion, expressionToText) ?? '',
      timeOfDay: timeOfDay(trigger.timeOfDay.hour, trigger.timeOfDay.minute),
      timeZone: trigger.timeZone,
    };
  }

  return trigger;
};

const serializeDecisionStep = (
  decisionStep: DecisionStep
): ServerDecisionStep => {
  const newNext = decisionStep.next.map((n) => ({
    ...n,
    criterion: maybe(n.criterion, expressionToText) ?? '',
  }));

  return {
    ...decisionStep,
    next: newNext,
  };
};

const serializeCommunicationStep = (
  step: CommunicationStep
): ServerCommunicationStep => {
  const { selectedChannels, ...serverStep } = step;
  const serializedChannels = step.channels.filter((c) =>
    selectedChannels.includes(c.name)
  );

  return {
    ...serverStep,
    channels: serializedChannels,
  };
};

const buildComplexExpression = (criterion: string): ComplexExpression => {
  const exp = normalizeExpression(textToExpression({ query: criterion }));
  return isComplexExpression(exp) ? exp : defaultComplexExpression(exp);
};

const buildSimpleExpression = (criterion: string): SimpleExpression => {
  const exp = textToExpression({ query: criterion });
  return isSimpleExpression(exp) ? exp : emptyExpression();
};
