import React from 'react';
import { useDesign } from 'contexts/design';
import { useDesignHooks } from 'components/publisher/theme/Design/useDesignHooks';
import { PublisherType } from 'models/library';
import { DesignComponent } from 'components/publisher/theme/Design/Component';
import { Design } from 'components/publisher/pages/Design';
import { useJourneyState } from 'contexts/journeys/journey';
import { usePortal } from 'hooks/usePortal';
import { createPortal } from 'react-dom';
import { LoadingScreen } from 'shared/LoadingScreen';
import { TemplatesModal } from 'publish/designs/TemplatesModal';
import { RouteComponentProps, Router } from '@reach/router';
import { NavigationBars } from './NavigationBars';
import { useJourneyContentDesigner } from './JourneyContentDesignProvider';
import styles from './styles.module.css';
import { ContentListState } from '../JourneyContentListDrawer/Drawer';
import { CommunicationPreview } from '../JourneyDrawer/CommunicationPreview';
import { DrawerState } from '../JourneyDrawer/drawer';
import { SettingsRoute } from './SettingsRoute';

export const JourneyContentDesignerComponent: React.FC = () => {
  const portal = usePortal('journey-content-designer');
  const { isDesignerOpen, closeDesigner } = useJourneyContentDesigner();
  const {
    activeStep,
    setContentListState,
    setDrawerState,
    setActiveStepId,
    drawerState,
  } = useJourneyState();

  const designContext = useDesign();
  const designEditor = useDesignHooks({
    design: designContext.design,
    update: designContext.update,
    publisherType: PublisherType.journeys,
  });

  React.useEffect(() => {
    if (activeStep?.id) {
      designContext.update({
        ...designContext.design,
        // parentId is skipped intentionally.
        parentSource: 'hermes',
        parentType: 'CommunicationNode',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep?.id]);

  const closeAndSetDesignerId = React.useCallback(
    (designId?: number) => {
      // we can't just set it in closeDesigner() because it has stale activeStep state
      closeDesigner(designId);
      designContext.refetch();

      // re-open the content list if the active step doesn't have a design
      // and the editor was closed without saving, otherwise keep it closed
      if (
        !designId &&
        activeStep &&
        activeStep.type === 'communication' &&
        !activeStep.designId
      ) {
        setContentListState(ContentListState.Open);
      }

      if (designId && activeStep && drawerState === DrawerState.Closed) {
        setDrawerState(DrawerState.Partial);
        setActiveStepId(activeStep.id);
      }
    },
    [
      activeStep,
      closeDesigner,
      designContext,
      drawerState,
      setActiveStepId,
      setContentListState,
      setDrawerState,
    ]
  );

  const navigationComponent = (
    <NavigationBars
      actionName="Save"
      canPerformAction
      closeAndSetDesignerId={closeAndSetDesignerId}
      action={() => {}}
      isWorking={designContext.status.isSaving}
      hideSave={designContext.design.blocks.length === 0}
    />
  );

  if (!isDesignerOpen || !activeStep || activeStep?.type !== 'communication') {
    return null;
  }

  const isReady = designEditor.ready && designContext.status.hasLoaded;
  return createPortal(
    <div className={styles.journeyDesignEditorModal}>
      {isReady && (
        <Router>
          <DesignComponent
            path="/design"
            designPermission={{ canEdit: true, errors: [], isLoading: false }}
            designEditor={designEditor}
            navigationComponent={navigationComponent}
            omitMenuItems={['card', 'personalizedFields']}
          >
            <DesignRoute
              path="/"
              previewEnabled={designEditor.previewEnabled}
            />
            <SettingsRoute path="/settings" />
          </DesignComponent>
        </Router>
      )}
      {!isReady && <LoadingScreen />}
    </div>,
    portal.target
  );
};

function DesignRoute(
  _props: RouteComponentProps & { previewEnabled?: boolean }
) {
  if (_props.previewEnabled) {
    return (
      <div className={styles.preview}>
        <CommunicationPreview fullSize />
      </div>
    );
  }
  return <Design implementation="new" />;
}

export const JourneyContentDesigner: React.FC = () => {
  const { activeStep } = useJourneyState();
  // The templates modal must be placed outside of the useBlocksEditor context.
  // This is because the blocks editor is not designed to accept new blocks from templates dynamically.
  // To prevent issues, we need to ensure that the blocks editor is instantiated only after the blocks from the template have been fully loaded.
  const {
    isDesignerOpen,
    isTemplateModalOpen,
    setIsTemplateModalOpen,
    navigateToInlineEditor,
    closeDesigner,
    inlineEditingEnabled,
  } = useJourneyContentDesigner();
  const { setContentListState } = useJourneyState();
  const portal = usePortal('journey-content-designer-templates');
  const designContext = useDesign();
  const closeTemplateModal = React.useCallback(() => {
    if (
      activeStep &&
      activeStep.type === 'communication' &&
      !activeStep.designId
    ) {
      setContentListState(ContentListState.Open);
    }
    setIsTemplateModalOpen(false);
  }, [activeStep, setContentListState, setIsTemplateModalOpen]);

  React.useEffect(() => {
    if (isDesignerOpen || isTemplateModalOpen) {
      setContentListState(ContentListState.Closed);
    }
  }, [isDesignerOpen, isTemplateModalOpen, setContentListState]);

  if (!inlineEditingEnabled) return null;

  if (!activeStep || activeStep.type !== 'communication' || !isDesignerOpen)
    return null;

  if (!isTemplateModalOpen && designContext.status.hasLoaded) {
    return <JourneyContentDesignerComponent />;
  }

  return createPortal(
    <div className={styles.journeyDesignEditorModal}>
      {isTemplateModalOpen && (
        <TemplatesModal
          onTemplateSelect={(t) => {
            designContext.createFromTemplate(t);
            navigateToInlineEditor();
          }}
          exitHandler={() => {
            closeDesigner();
            closeTemplateModal();
          }}
          close={closeTemplateModal}
        />
      )}
      {!isTemplateModalOpen && <LoadingScreen />}
    </div>,
    portal.target
  );
};
