import { useState } from 'react';
import { MAX_NOTIFICATION_LENGTH } from '../../../models/notification';
import { useProgram } from '../../../contexts/program';
import { useFeatureFlagsQuery } from '../../../hooks/feature-flags';
import { useSendTestEmail } from '../../../hooks/useSendTestEmail';
import {
  CheckedChannelsState,
  useChannelSelectionState,
} from '../../channel-selection/hooks';
import { User } from '../../../models/user';
import { useNotifications } from '../../../App/Program/Editors/Publisher/Deliver/Notifications/useNotifications';
import { useFeatureFlag } from '../../../hooks/useFeatureFlags';
import { usePublisher } from '../../../contexts/publisher';
import { Post } from '../../../models/publisher/post';
import { Design } from '../../../models/design';
import { useJourneyState } from '../../../contexts/journeys/journey';
import { useDesign } from '../../../contexts/design';
import {
  buildJourneysPermalink,
  DeliveryChannelName,
  isEmailChannel,
  isNotifitcationCenterChannel,
  isPushChannel,
} from '../../../models/journeys/journey';
import { sendTestPush } from '../../../services/api-post';
import { useNotificationCenterEnabled } from '../../../hooks/notification-center';
import { useFlashMessage } from '../../../contexts/flasher';
import { formatNotificationMessage } from './utils';

type ChannelName = keyof CheckedChannelsState;

export type DeliverySettings = {
  deliveryType: 'campaign' | 'communication';
  emailSettings: EmailSettings;
  pushSettings: PushSettings;
  notificationCenterSettings: NotificationCenterSettings;
};

type EmailSettings = {
  subject: string;
  previewText: string;
  post?: Post;
  design?: Design;
  previewPermalink?: string;
};

type PushSettings = {
  message: string;
};

type NotificationCenterSettings = {
  title: string;
  markAsImportant: boolean;
};

type EmailChannel = Omit<EmailSettings, 'post' | 'design'> & {
  name: 'email';
  valid: boolean;
  sendRequest: () => Promise<void>;
  setSubject: (subject: string) => void;
  setPreviewText: (text: string) => void;
};

type PushChannel = PushSettings & {
  name: 'push';
  valid: boolean;
  sendRequest: () => Promise<void>;
  setMessage: (message: string) => void;
};

type NotificationCenterChannel = NotificationCenterSettings & {
  name: 'notification_center';
  valid: boolean;
  sendRequest: () => Promise<void>;
  markAsImportant: boolean;
  setTitle: (title: string) => void;
  setMarkAsImportant: (markAsImportant: boolean) => void;
};

const useEmailChannel = (
  settings: EmailSettings,
  deliveryType: 'campaign' | 'communication',
  userIds: number[]
): EmailChannel => {
  const { id: programId } = useProgram();
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );

  const [subject, setSubject] = useState(settings.subject);
  const [previewText, setPreviewText] = useState(settings.previewText);

  const { sendEmailRequest } = useSendTestEmail({
    subject,
    previewText,
    userIds,
    preferOutlook365,
    post: settings.post,
    design: settings.design,
    type: deliveryType,
    previewPermalink: settings.previewPermalink,
  });

  const valid =
    subject.length !== 0 &&
    subject.length <= MAX_NOTIFICATION_LENGTH &&
    previewText.length <= MAX_NOTIFICATION_LENGTH;

  return {
    name: 'email',
    valid,
    sendRequest: sendEmailRequest,
    subject,
    previewText,
    setSubject,
    setPreviewText,
  };
};

const useNotificationCenterChannel = (
  settings: NotificationCenterSettings
): NotificationCenterChannel => {
  const [title, setTitle] = useState(settings.title);
  const [markAsImportant, setMarkAsImportant] = useState(
    settings.markAsImportant
  );

  const valid = title.length !== 0 && title.length <= MAX_NOTIFICATION_LENGTH;

  return {
    name: 'notification_center',
    valid,
    // TODO: https://firstup-io.atlassian.net/browse/FE-8935
    sendRequest: async () => {},
    title,
    markAsImportant,
    setMarkAsImportant,
    setTitle,
  };
};

const usePushChannel = (
  settings: PushSettings,
  userIds: number[]
): PushChannel => {
  const [message, setMessage] = useState(settings.message);
  const valid =
    message.length !== 0 && message.length <= MAX_NOTIFICATION_LENGTH;
  const { id: programId } = useProgram();

  const sendPushRequest = () => sendTestPush(message, programId, userIds);

  return {
    name: 'push',
    valid,
    sendRequest: sendPushRequest,
    message,
    setMessage,
  };
};

export const useSendTestCampaignSettings = (): DeliverySettings => {
  const { firstNotification: notification } = useNotifications();
  const { post } = usePublisher();

  const emailSettings: EmailSettings = {
    post,
    subject: notification.text,
    previewText: notification.previewText || '',
  };
  const pushSettings: PushSettings = {
    message: notification.pushText || '',
  };
  const notificationCenterSettings: NotificationCenterSettings = {
    title: notification.notificationCenterText || '',
    markAsImportant: Boolean(notification.notificationCenterMarkAsImportant),
  };

  return {
    deliveryType: 'campaign',
    emailSettings,
    pushSettings,
    notificationCenterSettings,
  };
};

export const useSendTestCommunicationSettings = (): DeliverySettings => {
  const { journey, activeStep } = useJourneyState();
  const { design } = useDesign();
  const { id: programId } = useProgram();
  const isCommunicationStep = activeStep?.type === 'communication';

  const previewPermalink = buildJourneysPermalink(
    programId,
    journey?.id
  )?.toString();

  const emailCommunication = isCommunicationStep
    ? activeStep.channels.find(isEmailChannel)
    : undefined;
  const pushCommunication = isCommunicationStep
    ? activeStep.channels.find(isPushChannel)
    : undefined;
  const notificationCenterCommunication = isCommunicationStep
    ? activeStep.channels.find(isNotifitcationCenterChannel)
    : undefined;

  const emailSettings: EmailSettings = {
    design,
    subject: emailCommunication?.subject || '',
    previewText: emailCommunication?.previewText || '',
    previewPermalink,
  };
  const pushSettings: PushSettings = {
    message: pushCommunication?.text || '',
  };
  const notificationCenterSettings: NotificationCenterSettings = {
    title: notificationCenterCommunication?.text || '',
    markAsImportant: Boolean(notificationCenterCommunication?.markAsImportant),
  };

  return {
    deliveryType: 'communication',
    emailSettings,
    notificationCenterSettings,
    pushSettings,
  };
};

export const useDeliveryChannels = (
  settings: DeliverySettings,
  initialCheckedChannels: Partial<CheckedChannelsState>,
  users: User[]
): {
  emailChannel: EmailChannel;
  notificationCenterChannel: NotificationCenterChannel;
  pushChannel: PushChannel;
  toggleChannel: (channel: ChannelName) => void;
  isChannelSelected: (channel: ChannelName) => boolean;
  isChannelEnabled: (channel: ChannelName) => boolean;
} => {
  const userIds = users.map(({ id }) => id);

  const { checked, toggleChecked } = useChannelSelectionState({
    initialCheckedChannels: {
      email: false,
      push: false,
      notification_center: false,
      ...initialCheckedChannels,
    },
  });

  const enabledChannels = useFeatureFlag('emailOnly')
    ? ['email']
    : Object.keys(initialCheckedChannels);

  const toggleChannel = (channel: ChannelName) => {
    if (!isChannelEnabled(channel)) return;
    toggleChecked(channel);
  };

  const isChannelEnabled = (channel: ChannelName) =>
    enabledChannels.includes(channel);
  const isChannelSelected = (channel: ChannelName) =>
    isChannelEnabled(channel) && checked(channel);

  const emailChannel = useEmailChannel(
    settings.emailSettings,
    settings.deliveryType,
    userIds
  );
  const notificationCenterChannel = useNotificationCenterChannel(
    settings.notificationCenterSettings
  );
  const pushChannel = usePushChannel(settings.pushSettings, userIds);

  return {
    isChannelSelected,
    isChannelEnabled,
    toggleChannel,
    emailChannel,
    pushChannel,
    notificationCenterChannel,
  };
};

export const useStatusNotifications = (): ((
  users: User[],
  results: {
    successfulChannels: DeliveryChannelName[];
    failedChannels: DeliveryChannelName[];
  }
) => void) => {
  const notificationCenterEnabled = useNotificationCenterEnabled();
  const { setFlashMessage } = useFlashMessage();

  // To keep the old behaviour
  if (!notificationCenterEnabled) {
    return (users: User[], _) => {
      const [user, ...otherUsers] = users;
      const userName = user.firstName || user.displayName;

      setFlashMessage({
        severity: 'info',
        message:
          otherUsers.length === 0
            ? `Test messages sent to ${userName}.`
            : `Test messages sent to ${userName} and ${otherUsers.length} other recipient(s).`,
      });
    };
  }

  const showStatusNotification = (
    channels: DeliveryChannelName[],
    severity: 'info' | 'error'
  ) => {
    if (channels.length === 0) return;

    setFlashMessage({
      severity,
      message: formatNotificationMessage(channels, severity),
    });
  };

  return (_, { successfulChannels, failedChannels }) => {
    showStatusNotification(successfulChannels, 'info');
    showStatusNotification(failedChannels, 'error');
  };
};
