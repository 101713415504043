import { useQuery } from 'react-query';
import { previewBlocks, validateBlock } from 'services/api-content-blocks';
import {
  DefinitionBlock,
  DataBlock,
  RenderError,
  Styling,
  Targets,
  isPollFieldData,
  isInputVariableFieldData,
  RenderingVariables,
} from 'models/publisher/block';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { Post } from 'models/publisher/post';
import { PreviewAs } from 'models/variable-previews';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { clone } from 'utility/deep-merge';
import { Permissions } from 'models/permissions';
import { usePermissions } from 'contexts/permissions';
import { useContext } from 'react';
import { JourneyContext } from 'contexts/journeys/journey';
import { useDebounce } from './useDebounce';
import { useFieldVariables } from './publisher/useFieldVariables';
import { useFeatureFlagsQuery } from './feature-flags';

export type PreviewablePost = {
  styles: Post['styles'];
  settings: Post['settings'];
  blocks: (DataBlock | DefinitionBlock)[];
  variables?: RenderingVariables;
};

type Block = PreviewablePost['blocks'][number];
type Blocks = PreviewablePost['blocks'];

function preprocessPostBlocks(
  blocks: Blocks,
  preprocessors: Array<(mutableBlock: Block) => void>
) {
  return blocks.map((block) => {
    const mutableBlock = clone(block);

    const processingErrors = preprocessors.map((preprocessor) => {
      try {
        return preprocessor(mutableBlock);
      } catch (e) {
        return e;
      }
    });
    if (processingErrors.some((e) => e instanceof Error)) {
      // _Silently_ fail to apply preprocessing, but log the error.
      // Rendering will still work, but there may be some missing data
      // eslint-disable-next-line no-console
      console.warn('Error applying preprocessing to block', processingErrors);
    }

    return mutableBlock;
  });
}

function preprocessPollData(block: Block, role: Permissions['role']) {
  if (role !== 'channel_contributor') return;

  Object.keys(block.field_data).forEach((name) => {
    const data = block.field_data[name];
    if (isPollFieldData(data)) data.show_results_to_user = true;
  });
}

function preprocessAuthorName(block: Block, authorName: string | undefined) {
  const authorNameField = block.field_data.author_name;
  if (
    authorNameField &&
    isInputVariableFieldData(authorNameField) &&
    authorName
  ) {
    authorNameField.value = authorName;
  }
}
function preprocessAuthorAvatar(block: Block, avatarUrl: string | undefined) {
  const authorAvatar = block.field_data.author_avatar;
  if (authorAvatar && isInputVariableFieldData(authorAvatar) && avatarUrl) {
    authorAvatar.value = avatarUrl;
  }
}

export const usePreview = (
  post: PreviewablePost,
  delivery?: Targets,
  webFontsEnabled?: boolean,
  previewAs?: PreviewAs,
  permittedPersonalizedFieldFiles?: string[]
): {
  html: string;
  isLoading: boolean;
  errors: RenderError[];
} => {
  const program = useProgram();
  const { post: currentPost } = usePublisher();
  const debouncedStyle = useDebounce(post.styles);
  const { settings } = useSettings();
  const { role } = usePermissions();
  const isJourney = useContext(JourneyContext) !== null;

  const authorName = isJourney
    ? post.variables?.author_name || ''
    : settings.contentAuthor?.displayName || '';

  const avatarUrl = isJourney
    ? post.variables?.author_avatar || ''
    : settings.contentAuthor?.avatarUrl;

  const processedBlocks = preprocessPostBlocks(post.blocks, [
    (block) => preprocessPollData(block, role),
    (block) => preprocessAuthorName(block, authorName),
    (block) => preprocessAuthorAvatar(block, avatarUrl),
  ]);

  const { fromPost } = useFieldVariables();
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(program.id, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );
  const variables = isJourney ? post.variables || {} : fromPost(post);
  const { isLoading, error, data } = useQuery<string, Error>(
    [
      `content_blocks::preview`,
      {
        blocks: processedBlocks,
        programId: program.id,
        style: debouncedStyle,
        delivery,
        webFontsEnabled,
        previewAs,
        contentId: currentPost.content.id,
        permittedPersonalizedFieldFiles,
      },
    ],
    () =>
      previewBlocks(
        program.id,
        processedBlocks,
        debouncedStyle,
        delivery,
        variables,
        previewAs,
        { webFontsEnabled: Boolean(webFontsEnabled), preferOutlook365 },
        currentPost.content.id,
        permittedPersonalizedFieldFiles
      ),
    {
      retry: false,
    }
  );
  const errors: RenderError[] = [];
  if (error?.message) {
    try {
      errors.push(...JSON.parse(error.message).errors);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return {
    isLoading,
    html: data ? (!error && data) || '' : '',
    errors,
  };
};

export const useRenderer = (
  block: DefinitionBlock,
  style: Styling,
  delivery?: Targets
): {
  html: string;
  isLoading: boolean;
  errors: RenderError[];
} => {
  const { post } = usePublisher();
  return usePreview(
    { blocks: [block], styles: style, settings: post.settings },
    delivery
  );
};

export const useValidator = ({
  block,
  enabled = true,
  onSettled = () => {},
}: {
  block: DefinitionBlock;
  enabled?: boolean;
  onSettled?: () => void;
}): {
  isLoading: boolean;
  valid: boolean;
  errors: RenderError[];
} => {
  const program = useProgram();
  const debouncedBlock = useDebounce(block);
  const { isLoading, data } = useQuery(
    [
      'content_blocks::validate',
      {
        debouncedBlock,
        programId: program.id,
      },
    ],
    () => validateBlock(program.id, debouncedBlock),
    {
      retry: false,
      enabled,
      onSettled: () => onSettled(),
    }
  );
  return {
    isLoading,
    valid: data?.valid ?? true,
    errors: data?.errors ?? [],
  };
};
